import React from "react"
import logo from "./anna.jpeg"

const Header = () => {
  return (
    <>
      <div class="container">
        <div class="flex flex-col lg:flex-row h-screen">
          <div class="flex self-center">
            <img
              class="rounded-full transform scale-50"
              src={logo}
              alt="Anna Rzepczynski"
            />
          </div>
          <div class="lg:w-6/12 self-center m-4 md:m-0">
            <h1 class="text-5xl">Anna Rzepczynski</h1>
            <h3 class="text-purple text-3xl">
              Service Design | Facilitation | Experience
            </h3>
            <p class="pt-4">I had a beautiful and informative website.</p>
            <p class="pt-4">
              Designed by a talented friend and built by another 4 years ago.
            </p>
            <p class="pt-4">
              That site was lost. Completely. All the content, everything.
            </p>
            <p class="pt-4">
              Now I don't know what to do. Questions going round my head at the
              moment include:
              <ul class="list-disc list-inside">
                <li>How do I want to present myself?</li>
                <li>What content should I share?</li>
                <li>Do I even need a website?</li>
              </ul>
            </p>
            <p class="pt-4">
              So for now, if you're looking for my work experience check out my{" "}
              <a
                class="underline hover:no-underline"
                href="https://www.linkedin.com/in/annarzepczynski/"
                target="_blank"
              >
                LinkedIn
              </a>
              .
            </p>
            <p class="pt-4">
              If you want to know more about my interests and how I've gained
              that experience get in touch:{" "}
              <a
                class="underline hover:no-underline"
                href="mailto:anna@zep-chin-ski.com"
              >
                anna@zep-chin-ski.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
